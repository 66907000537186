import {
  createOwnUpComponent,
  createOwnUpStyle
} from '@rategravity/own-up-component-library-legacy';
import React from 'react';
import styled from 'styled-components';
import { whyOwnUpLenderNetworkData } from '../../../data/content/lender-network';
import { Catchphrase, PrimaryTextMixin, SectionHeader } from '../../typography';

const ListWrapperStyle = createOwnUpStyle({
  variants: {
    mediumAndDown: {
      paddingBottom: 30
    }
  }
});

const ListWrapper = createOwnUpComponent('div', ListWrapperStyle);

const PrimaryText = styled('span')`
  ${PrimaryTextMixin}
  font-size: 15px;
`;

export const WhyOwnUpContent = () => (
  <>
    <SectionHeader variant="title" level={2}>
      Why Own Up
    </SectionHeader>
    <ListWrapper>
      {whyOwnUpLenderNetworkData.map(({ header, points }, index) => (
        <React.Fragment key={index}>
          <Catchphrase>{header}</Catchphrase>
          <ul style={{ paddingLeft: 20 }}>
            {points.map((point, i) => (
              <li key={i} style={{ marginBottom: '10px' }}>
                <PrimaryText>{point}</PrimaryText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      ))}
    </ListWrapper>
  </>
);
